/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle, Image, Button, Fullmap, FullmapWrap, FullmapCover } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Vinný sklep"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-4y8bkk --style2 --full pb--60 pt--60" name={"v01kxbrzml"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19433/67c7bfed861e4a0ba77eca1e63aba2ed_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19433/67c7bfed861e4a0ba77eca1e63aba2ed_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19433/67c7bfed861e4a0ba77eca1e63aba2ed_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19433/67c7bfed861e4a0ba77eca1e63aba2ed_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19433/67c7bfed861e4a0ba77eca1e63aba2ed_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19433/67c7bfed861e4a0ba77eca1e63aba2ed_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19433/67c7bfed861e4a0ba77eca1e63aba2ed_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19433/67c7bfed861e4a0ba77eca1e63aba2ed_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3" anim={"2"} animS={"3"}>
              
              <Title className="title-box fs--86 title-box--invert" content={"Vinný sklep - Vinohrady<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"informace-1"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --left el--1 flex--top" anim={"2"} animS={"3"} style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper className="--center" animS={"3"} style={{"maxWidth":""}}>
              
              <Title className="title-box lh--12" style={{"maxWidth":1182}} content={"Nabízíme možnost ochutnávky našich vín <br>v typickém kvelbeném sklepě."}>
              </Title>

              <Text className="text-box mt--25 pl--0 pr--0" style={{"maxWidth":1102}} content={"&nbsp;Degustace pouze pro ubytované, po dohodě. Ve sklepě není možnost posezení, pouze stání u \"beček\" a bez občerstvení.<br>Starý sklep se nachází ve Strachotíně, ve vinařské uličce Sklepní u rybníka (není součástí areálu vinařství a penzionu).<br>"}>
              </Text>

              <Text className="text-box mt--06 pl--0 pr--0" content={"Nabízíme prodej moravských lahvových vín naší výroby, více v záložce Naše vína."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"otwqnesafc"} style={{"backgroundColor":"rgba(252,251,250,1)"}} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper className="pb--40 pl--40 pr--40 pt--40" style={{"maxWidth":"","backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Subtitle className="subtitle-box fs--30" style={{"maxWidth":366}} content={"Vinařské akce<br><br>"}>
              </Subtitle>

              <Subtitle className="subtitle-box subtitle-box--left ff--2 fs--16" style={{"maxWidth":1000,"paddingRight":0}} content={"1.4.2023&nbsp; &nbsp; &nbsp; Jarní otevřené sklepy<br>3.6.2023&nbsp; &nbsp; &nbsp;Letní otevřené sklepy<br>16.9.2023&nbsp; Za burčákem do Strachotína<br>4.11.2023&nbsp; &nbsp; &nbsp;Podzimní otevřené sklepy<br>27.12.2023&nbsp; Svěcení vína s ochutnávkou<br>"}>
              </Subtitle>

              <Image src={"https://cdn.swbpg.com/t/19433/c55796f43e5c47ea9c8547f49e3df50f_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/19433/c55796f43e5c47ea9c8547f49e3df50f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19433/c55796f43e5c47ea9c8547f49e3df50f_s=660x_.jpg 660w"}>
              </Image>

              <Button className="btn-box" url={"https://cdn.swbpg.com/t/11212/24fa61ff35b04e95b0c94d3bbad48b74.jpeg"} href={"https://cdn.swbpg.com/t/11212/24fa61ff35b04e95b0c94d3bbad48b74.jpeg"} content={"Zobrazit"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="pb--40 pl--40 pr--40 pt--40" style={{"maxWidth":"","backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Subtitle className="subtitle-box fs--30" style={{"maxWidth":366}} content={"Ceník"}>
              </Subtitle>

              <Image src={"https://cdn.swbpg.com/t/11212/152afe91bdd7438697bf294ea127d1df_s=350x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":128}} srcSet={"https://cdn.swbpg.com/t/11212/152afe91bdd7438697bf294ea127d1df_s=350x_.jpeg 350w"}>
              </Image>

              <Button className="btn-box" content={"Stáhnout .PDF (připravujeme)"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="pb--40 pl--40 pr--40 pt--40" style={{"maxWidth":"","backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Subtitle className="subtitle-box fs--30" style={{"maxWidth":366}} content={"Krojované hody&nbsp;"}>
              </Subtitle>

              <Text className="text-box fs--20" content={"<span style=\"font-weight: bold;\">4.-6.8.2023</span><br>"}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/11212/8822979455d14f18a0407c599b32662a_s=860x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/8822979455d14f18a0407c599b32662a_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/11212/8822979455d14f18a0407c599b32662a_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/11212/8822979455d14f18a0407c599b32662a_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/11212/8822979455d14f18a0407c599b32662a_s=1400x_.jpeg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--60 pt--60" name={"tu12amoiuio"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19433/7a2bcaddc3e149a9afdf70cc6e65d3a3_s=860x_.JPEG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19433/7a2bcaddc3e149a9afdf70cc6e65d3a3_s=350x_.JPEG 350w, https://cdn.swbpg.com/t/19433/7a2bcaddc3e149a9afdf70cc6e65d3a3_s=660x_.JPEG 660w, https://cdn.swbpg.com/t/19433/7a2bcaddc3e149a9afdf70cc6e65d3a3_s=860x_.JPEG 860w, https://cdn.swbpg.com/t/19433/7a2bcaddc3e149a9afdf70cc6e65d3a3_s=1400x_.JPEG 1400w, https://cdn.swbpg.com/t/19433/7a2bcaddc3e149a9afdf70cc6e65d3a3_s=2000x_.JPEG 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19433/94944a95e2aa4465a3e7f5b12e325a9d_s=860x_.JPEG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19433/94944a95e2aa4465a3e7f5b12e325a9d_s=350x_.JPEG 350w, https://cdn.swbpg.com/t/19433/94944a95e2aa4465a3e7f5b12e325a9d_s=660x_.JPEG 660w, https://cdn.swbpg.com/t/19433/94944a95e2aa4465a3e7f5b12e325a9d_s=860x_.JPEG 860w, https://cdn.swbpg.com/t/19433/94944a95e2aa4465a3e7f5b12e325a9d_s=1400x_.JPEG 1400w, https://cdn.swbpg.com/t/19433/94944a95e2aa4465a3e7f5b12e325a9d_s=2000x_.JPEG 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19433/841d47d75378420d9b2f323f37792fa7_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19433/841d47d75378420d9b2f323f37792fa7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19433/841d47d75378420d9b2f323f37792fa7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19433/841d47d75378420d9b2f323f37792fa7_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19433/3da5d55f83ab4518956c7757ca4fa28c_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19433/3da5d55f83ab4518956c7757ca4fa28c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19433/3da5d55f83ab4518956c7757ca4fa28c_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19433/8ff1e2880708408d95e9f97a6b3bca6c_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19433/8ff1e2880708408d95e9f97a6b3bca6c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19433/8ff1e2880708408d95e9f97a6b3bca6c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19433/8ff1e2880708408d95e9f97a6b3bca6c_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19433/be0ea8fab8294e319560c0e21f6945d8_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19433/be0ea8fab8294e319560c0e21f6945d8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19433/be0ea8fab8294e319560c0e21f6945d8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19433/be0ea8fab8294e319560c0e21f6945d8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19433/be0ea8fab8294e319560c0e21f6945d8_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19433/6c85d4e74d3a481786f5dd8424ba1d99_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19433/6c85d4e74d3a481786f5dd8424ba1d99_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19433/6c85d4e74d3a481786f5dd8424ba1d99_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19433/6c85d4e74d3a481786f5dd8424ba1d99_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11212/c67f1efdab3447e1b5bfbfe55240a290_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/c67f1efdab3447e1b5bfbfe55240a290_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/11212/c67f1efdab3447e1b5bfbfe55240a290_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/11212/c67f1efdab3447e1b5bfbfe55240a290_s=860x_.jpeg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19433/14294ed93f9f4385aec0d1a317a7a92a_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19433/14294ed93f9f4385aec0d1a317a7a92a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19433/14294ed93f9f4385aec0d1a317a7a92a_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19433/17b9f991ae1847199afdcd6b66b0e4a4_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19433/17b9f991ae1847199afdcd6b66b0e4a4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19433/17b9f991ae1847199afdcd6b66b0e4a4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19433/17b9f991ae1847199afdcd6b66b0e4a4_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11212/1db6ee36a7a74ccb98ea2eeabb648fb1_s=660x_.jpeg"} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/1db6ee36a7a74ccb98ea2eeabb648fb1_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/11212/1db6ee36a7a74ccb98ea2eeabb648fb1_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11212/bac7dbf7deeb495699844c1da2f58107_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/bac7dbf7deeb495699844c1da2f58107_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/11212/bac7dbf7deeb495699844c1da2f58107_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"y9epn54zt2"}>
          
          <FullmapWrap className="--full">
            
            <FullmapCover zoom={"11"} place={"Pouzdřanská 340, Strachotín"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Fullmap name={"kufbcwm7nka"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column className="pb--50 pt--50" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" anim={""} animS={"2"} style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box subtitle-box--left mt--06 pl--0" content={"<span style=\"color: rgb(255, 255, 255);\">VINAŘSTVÍ KNÁPEK</span>"}>
              </Subtitle>

              <Text className="text-box text-box--left mt--02" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-dominant);\">s.r.o.</span>"}>
              </Text>

              <Text className="text-box text-box--left" style={{"maxWidth":""}} content={"<span style=\"color: rgb(255, 255, 255);\">Pouzdřanská 340<br>693 01 Strachotín<br>IČO 05868751<br>DIČ CZ05868751</span><br>"}>
              </Text>

              <Text className="text-box text-box--left" style={{"maxWidth":366}} content={"<span style=\"color: rgb(255, 255, 255);\"><a href=\"https://tvorba-stranek-brno.cz/\">Tvorba webových stránek Brno</a></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":485}}>
              
              <Text className="text-box text-box--left" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-dominant);\">Vinařství — Libor Knápek</span>"}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--left ls--0 mt--06" content={"<span style=\"color: rgb(255, 255, 255);\">(420) 777 574 207</span><span style=\"color: var(--color-dominant);\"><br></span>"}>
              </Subtitle>

              <Text className="text-box text-box--left" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-dominant);\">Ubytování — Jana Knápková</span>"}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--left ls--0 mt--06" content={"<span style=\"color: rgb(255, 255, 255);\">(420) 608 423 866</span><span style=\"color: var(--color-dominant);\"><br></span>"}>
              </Subtitle>

              <Text className="text-box text-box--left" style={{"maxWidth":366}} content={"<span style=\"color: rgb(255, 255, 255);\"><a href=\"mailto:info@ubytovani-strachotin-knapek.cz\">info@ubytovani-strachotin-knapek.cz</a></span><br>"}>
              </Text>

              <Text className="text-box text-box--left mt--0" style={{"maxWidth":366}} content={"<span style=\"color: rgb(255, 255, 255);\"><a href=\"https://www.facebook.com/VinarstviKnapek/\">https://www.facebook.com/VinarstviKnapek/</a></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Button className="btn-box btn-box--sbtn2 btn-box--cColor2" use={"page"} content={"Úvod"}>
              </Button>

              <Button className="btn-box btn-box--sbtn2 btn-box--cColor2 mt--08" use={"page"} content={"Ubytování"}>
              </Button>

              <Button className="btn-box btn-box--sbtn2 btn-box--cColor2 mt--08" use={"page"} content={"Vinní sklep"}>
              </Button>

              <Button className="btn-box btn-box--sbtn2 btn-box--cColor2 mt--08" use={"page"} content={"Ceník"}>
              </Button>

              <Button className="btn-box btn-box--sbtn2 btn-box--cColor2 mt--08" use={"page"} content={"Kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}